import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
// import {exchangeCodeForAccessToken, exchangeNpssoForCode, makeUniversalSearch, getUserTrophiesEarnedForTitle } from "psn-api";
export default function Home() {

    function gotomail() {
        window.location.href = "mailto:biz@grimmbytes.com"
    }

    return (
    <>  
        <title>Grimm's Corner</title>
        <h1>
            Hire GrimmXIII Perhaps?
        </h1>
        <main id='main'>
            <div className="box">
                <h2>Technology Refurbishment</h2>
                <p className="colorWhiteHahahahahahaha">
                    I purchase dead/dying technology, I then repair it to its needs to get it operational again, then I would resell it.
                    Either on eBay or a few other vendors. Sometimes I offer unique things for these producs, but most notable would be my
                    Warranty. Lets be honest, Sometimes shit happens. Either something happens in transit, or sometimes I might perhaps miss
                    something from the device that it didn't show me while in my shop. Either way, I offer a free 1 Month Warranty on my devices.
                    In short, this warranty would cover your device if it stops working for any reason that wasn't caused by the buyer/user of
                    the device. Each device that I sell goes with a unique G-Code (Grimm Code). If you enter this code on my site, this can show
                    you information such as updated warranty status, or a status on the unique things that go with the device. This code will
                    not have any personal buyer information attatched to it. Only the vendor that it was purchased from. Nothing else!
                </p>
                <input placeholder="Input your G-Code here" className="grinput"></input>
                <p className="colorWhiteHahahahahahaha">
                    While I typically buy my own devices to repair, I am interested in doing repair work for your own devices as well. If
                    you want to hire me to repair your computer, I am interested. Shoot me an email!
                </p>
                <button className="grimutton" onClick={gotomail}>
                    Hire me to repair your computer!
                </button>
                <p className="colorWhiteHahahahahahaha">
                    If you are about to throw something away because its broken, I might be interested (not guaranteed!). shoot me an email
                    of your devices specs. What helps me come to a conclusion is if you share information on your devices CPU, RAM, Brand, Model
                    Number, Windows version, and the year at which you purchased it. If I am interested, I will let you know how much I will pay for
                    it. Afterwards, I will provide you with an Virtual Address to send your package to, and once I get your package and verify its
                    integrity, I will then pay you in full along with partial cost of shipping (Up to 20$). Interested? Shoow me an email!
                </p>
                <button className="grimutton"  onClick={gotomail}>
                    Sell me your computer!
                </button>


            </div>

            <div className="box">
                <h2>Website Designing</h2>
                <p className="colorWhiteHahahahahahaha">
                    If it aint clear already, I like to work on websites :) This is more of my side stuff, however I still am very fluent
                    in pure HTML/CSS and React.JS. I have recently started to offer my labor on Website designing! I have had 3+ Years
                    experience with working on code, I have had 2+ Years experience on working with Domains. I have the knowledge that you
                    need to get a website up and running with its own domain! Anything that may be confusing, I can help you out with, or
                    take care of it for ya! If hired, I want to make your site just as how you imagined. I want you to give me your idea of
                    what you are looking for, and I will keep you updated on the design. No fuss, no muss! Interested? Shoot me an email!
                    I do want to mention that I am not looking to code for anything illegal. Don't try it please :)<br/>
                    Also! I do not work with databased publicly yet. Hence, I cannot do things like online stores, or have consoles that
                    customers can use to edit their site. While I hope that I can plan this stuff out soon, Its just not available yet.
                    I can confidently make you a static site, but I'm not sure that I can help you out with much back-end functionality yet
                </p>
                <button className="grimutton"  onClick={gotomail}>
                    Get your website up today!
                </button>
            </div>
        </main>






    </>
    );
}
