import './styles/social.css';
import './styles/styles.css';
import Particles from './components/particles.js';
import PageRouter from "./components/router.js";
import { Router, Link, useLocation } from "wouter";
import React, {useState, useEffect} from "react";

function App() {
    
    const [ pathname ] = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <nav>
                        <Link href='/account' id='navButton5'><div className="loginButton activeAcc" >
                            <img src='/images/default.png' className='navLogin' />
                        </div></Link>

                        <Link href='/' id='navButton1'><div class="navButton active1" >Home</div></Link>
                        <Link href='/about' id='navButton2'><div class="navButton active2" >About</div></Link>
                        <Link href='/status' id='navButton3'><div class="navButton active3" >Status</div></Link>
                        <Link href='/stats' id='navButton4'><div class="navButton active4" >Stats</div></Link>
                        <Link href='/projects' id='navButton5'><div class="navButton active5" >Projects</div></Link>
                        <Link href='/grimmServices' id='navButton7'><div class="navButton active6" >Grimm<br/>Services</div></Link>
                        <Link href='/blog' id='navButton6'><div class="navButton active7" >Blog</div></Link>
                    </nav>
                </header>
                <PageRouter />
                <footer>
                    <img src="/images/lilgrim.png" id="lilgrim"/>
                    <br/>
                    Thanks for stoppin by! -GrimmXIII :)
                </footer>
                <Particles id="tsparticles" />
            </div>
        </Router>
    );
}

export default App;
