import React, { useState, useEffect } from "react";
import { Link } from "wouter";

export default function Hopes() {
    function warning(num) {
        var mainElem = document.getElementById("warning");
        var boom = new Audio('misc/boom.mp3')
        if (num < 5) {
            mainElem.classList.remove("shake");
            void mainElem.offsetWidth;
            mainElem.classList.add("shake");
            num = num + 1;
        } else
        if (num >= 5) {
            boom.play();
            mainElem.classList.add("shakeBadder"); 
            num = num + 1;
        }
    }
    
    function proj(num, link) {
        //const link = '';
        const button = document.getElementById("proj" + num);
        const warningDiv = document.getElementById("warning");
        button.classList.add('projLinkAnim')
    
        setTimeout(function() {
            window.location = 'https://' + link;
        }, 1000);
    
        setTimeout(function() {
            button.classList.remove('projLinkAnim');
            void button.offsetWidth;
            button.classList.add('projLinkAnimBack');
        }, 5000);
    
        setTimeout(function() {
            button.classList.remove('projLinkAnimBack');
            void button.offsetWidth;
        }, 6000);
    }
    
return (
    <>
        <title>Grimm's Corner - FNF Hopes and Dreams</title>
        <div id='warning' className="warning">
            <img src="/images/warning.png"/>
            <h2>Dev Team Disbanded!</h2>
            <p>Due to the director's Discord account getting hacked, everyone was kicked from the dev team, and saddly, I haven't friended anybody yet (My bad) Until I hear a response from the team, this project has been halted!</p>
        </div>
        <div id='issue' className="issue">
            <img src="/images/issue.png"/>
            <h2>This Project only is a Demo!</h2>
            <p>This is still a WIP, and not finished yet, there is a Demo, however the final product is still not out, hold tight, itll be a while!</p>
        </div>
        <h1>
            FNF: Hopes and Dreams
        </h1>
        <main id="main">
            <div className="box top-box">
                <p>When Boyfriend falls down Mt. Ebott, he traverses the Underground, but of course it aint a simple journey. what do you think? Will BF choose to be a Pacifist? or do you think he would rather Attack? its not up for me to decide!</p>
            </div>
            <div className="main-box-container">
                <div className="box-left">
                    <div className="box">
                        <h2 className="creditsTitle">Credits</h2>
                        <div className="creditsWrapper">
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/funkin.png" />
                                <p>Creator</p>
                                <p>FunkinBoy J</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/blue.png" />
                                <p>Artist</p>
                                <p>Bluebox</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/shawn.png" />
                                <p>Charter</p>
                                <p>Shawn_Dev</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/shark.png" />
                                <p>Charter</p>
                                <p>SharkyBoi</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/grimm.png" />
                                <p>Coder</p>
                                <p>GrimmXIII</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/clown.png" />
                                <p>Musician</p>
                                <p>Frostbite Clown</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/inn.png" />
                                <p>Musician</p>
                                <p>Inneaux</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/hopes/credits/copper.png" />
                                <p>Former Charter</p>
                                <p>Dr. Copper</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="box-right">
                    <div className="box">
                        <div className="projLink" onClick={() => proj('1', 'gamebanana.com/mods/469020')} id="proj1">
                            Gamebanana Page
                            <div className="projLink-box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1>
                What this has to offer...
            </h1>
            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/hopes/img2.png" className="projImgLeft"/>
                    <h3>The Demo</h3>
                    <p>While it may take a while for the final product to be released, we wanted to give everybody a small taste of what we have done so far!</p>
                    <p>If there are any intentional leaks, then they will be shown here, however we havent decided on any sneak previews yet, but we will soon!</p>
                </div>
            </div>
            <div className="main-box-container">
                <div className="box-left">

                    <div className="box">
                        <h2 style={{fontSize: "45px"}}>Updates!</h2>
                        <div className="update">
                            <h2>grimm.pw Creation Status</h2>
                            <h6>v1.0.0 - Jan 18 2024</h6>
                            <ul>
                                <li style={{color: "lime"}}>Demo Released!</li>

                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="box-right">
                    <div className="box">
                        <div className="projLink" onclick="proj('3', 'gamebanana.com/mods/469020')" id="proj3">
                            Gamebanana Page
                            <div className="projLink-box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
    );
}
