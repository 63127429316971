import React, { useState, useEffect } from "react";
import { Link } from "wouter";

export default function Home() {
function warning(num) {
    var mainElem = document.getElementById("warning");
    var boom = new Audio('misc/boom.mp3')
    if (num < 5) {
        mainElem.classList.remove("shake");
        void mainElem.offsetWidth;
        mainElem.classList.add("shake");
        num = num + 1;
    } else
    if (num >= 5) {
        boom.play();
        mainElem.classList.add("shakeBadder"); 
        num = num + 1;
    }
}
    
function proj(num, link) {
    //const link = '';
    const button = document.getElementById("proj" + num);
    const warningDiv = document.getElementById("warning");
    button.classList.add('projLinkAnim')

    setTimeout(function() {
        window.location = 'https://' + link;
    }, 1000);

    setTimeout(function() {
        button.classList.remove('projLinkAnim');
        void button.offsetWidth;
        button.classList.add('projLinkAnimBack');
    }, 5000);

    setTimeout(function() {
        button.classList.remove('projLinkAnimBack');
        void button.offsetWidth;
    }, 6000);
}
    
return (
    <>  
        <title>Grimm's Corner - Cracker Clicker</title>
        <h1>
            Cracker Clicker
        </h1>
        <main id="main">
            <div className="box top-box">
                <p>Cookie Clicker?? Nahhh, we got Cracker Clicker! Make your way through the world by giving people your Ritz, your Saltines, and a crap ton more!</p>
            </div>
            <div className="main-box-container">
                <div className="box-left">
                    <div className="box">
                        <h2 className="creditsTitle">Credits</h2>
                        <div className="creditsWrapper">
                            <div className="credit">
                                <img src="/images/projects/cracker/credits/ect.png" />
                                <p>Inspired By</p>
                                <p>ablack24</p>
                                <a href="https://www.cs.mcgill.ca/~ablack24/Crackers/cracker_clicker.html"><button className="creditButton">Look at their version!</button></a>
                            </div>                            
                            <div className="credit">
                                <img src="/images/projects/cracker/credits/grimm.png" />
                                <p>Creator</p>
                                <p>GrimmXIII</p>
                            </div>
                            <div className="credit">
                                <img src="/images/projects/cracker/credits/ect.png" />
                                <p>Contributer</p>
                                <p>Random Friends</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="box-right">
                    <div className="box">
                        <div className="projLink" onClick={() => proj('1', 'cracker-clicker.glitch.me')} id="proj1">
                            Try it Out!
                            <div className="projLink-box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1>
                What this has to offer...
            </h1>
            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/cracker/img7.png" className="projImgLeft"/>
                    <h3>Click to get ahead!</h3>
                    <p>The fun twist is that you cannot earn from offline access, instead, you get inflated clicking prices! Increase your CPC (Crackers per Click) to make it easier to get more crackers!</p>
                </div>
            </div>

            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/cracker/img2.png" className="projImgRight"/>
                    <h3>Hire some Help</h3>
                    <p>You can't go through this journey alone, Hire more friends to assist you along your saga! the more friend means the better your company does. Monopolize the entire cracker market!</p>
                </div>
            </div>


            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/cracker/img4.png" className="projImgLeft"/>
                    <h3>Sacrifice it all...</h3>
                    <p>Would you do it? restart for a little bost? your prestige level is a multiplier, CPC x Prestige. Do you think its worth the risk to get rid of it all, just for a multiplier. Your hired friends would not remember you, your monopoly ceaces to exist, you are back to a normal person, without an industry. Try to show us that you are determined enough to work your way back up!</p>
                </div>
            </div>


            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/cracker/img3.png" className="projImgRight"/>
                    <h3>A Golden Cracker!?</h3>
                    <p>Now this... is epic! I golden cracker that can offer temporary benefits or maybe brings you a new friend?? These have a 1/1000 chance of spawning every second. and they can spawn if you are AFK, you might need one of these to spawn late game to prestige, it gets a little difficult then!</p>
                </div>
            </div>


            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/cracker/img6.png" className="projImgLeft"/>
                    <h3>Dominate the Leaderboard! (not yet)</h3>
                    <p>Show us that your universe is the greatest! but not only us, rub it in everyones face that you are the best of the best! You are determined enough to be the cracker god!</p>
                </div>
            </div>

            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/cracker/img5.png" className="projImgRight"/>
                    <h3>Are you ready?</h3>
                    <p>As always, this is free, it may be just another ripoff, but if Cracker Clicker is blocked, and you wanna kill some time, then I would suggest this</p>
                </div>
            </div>
            
            <div className="main-box-container">
                <div className="box-left">

                    <div className="box">
                        <h2 style={{fontSize: "45px"}}>Updates!</h2>
                        <div className="update">
                            <h2>grimm.pw Creation Status</h2>
                            <h6>v1.0.0 - Jan 18 2024</h6>
                            <ul>
                                <li>Game is currently stable</li>
                                <li>The Skin system is a WIP, and not exactly working</li>
                                <li style={{color: "red"}}>This game has a planned rehaul, however, there are other planned events that are higher priority. A rehaul date is not made, and its not sure when it will be made. again, while a rehaul is guaranteed, its not yet planned. The current state of the game is stable, and in working order.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="box-right">
                    <div className="box">
                        <div className="projLink" onClick={() => proj('3', 'cracker-clicker.glitch.me')} id="proj3">
                            Try it Out!
                            <div className="projLink-box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
    );
}
