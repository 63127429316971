import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {
    const [location, setLocation] = useLocation();
    let num = 0;

    useEffect(() => {
        const socialButtons = document.querySelectorAll('.social-email, .social-twitter, .social-spotify, .social-soundcloud, .social-youtube-solo, .social-youtube-trio, .social-discord, .social-gamebanana, .social-github, .social-gamejolt, .social-steam, .social-epic-games, .social-playstation, .social-minecraft, .social-tetrio');
  
        socialButtons.forEach(button => {
            const imgClass = button.classList[0].replace('social-', 'img-');
            const img = document.querySelector(`.${imgClass}`);
            const oldImg = document.querySelector(`.img-old`);

            if (img) {
                button.addEventListener('mouseenter', () => {
                    img.style.display = 'inline-block';
                    oldImg.style.display = 'none';
                });
    
                button.addEventListener('mouseleave', () => {
                    img.style.display = 'none';
                    oldImg.style.display = 'inline-block';
                });
            }
        });
    }, []);
  
    const warning = () => {
        const mainElem = document.getElementById("warning");
        const boom = new Audio('misc/boom.mp3');

        if (num < 5) {
            mainElem.classList.remove("shake");
            void mainElem.offsetWidth;
            mainElem.classList.add("shake");
            num = num + 1;
        } else if (num >= 5) {
            boom.play();
            mainElem.classList.add("shakeBadder");
            num = num + 1;
        }
    };
  
    function nopeShake(num) {
        const mainElem = document.getElementById(num === 1 ? "epicBad" : "minecraftBad");

        mainElem.classList.remove("shake");
        void mainElem.offsetWidth;
        mainElem.classList.add("shake");
    };
  
    const proj = (num, link) => {
        const button = document.getElementById("proj" + num);
        const warningDiv = document.getElementById("warning");
        button.classList.add('projLinkAnim');

        setTimeout(() => {
            if (num === '1' || num === '2' || num === '3') {
                setLocation("/" + link)

            } else {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                // warning();
            }
        }, 1000);
  
        setTimeout(() => {
            button.classList.remove('projLinkAnim');
            void button.offsetWidth;
            button.classList.add('projLinkAnimBack');
        }, 5000);

        setTimeout(() => {
            button.classList.remove('projLinkAnimBack');
            void button.offsetWidth;
        }, 6000);
    };
  
const about = () => {
const aboutButton = document.getElementById('aboutButton');
const navButtons = document.getElementsByClassName('navButton');
const navButton = document.getElementById('navButton2');
aboutButton.classList.add('aboutButtonMove');

setTimeout(() => {
    for (let i = 0; i < navButtons.length; i++) {
        navButtons[i].classList.add('navButtonGlow');
    }}, 500);

    setTimeout(() => {
        warning();
    }, 1500);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonMove');
        void aboutButton.offsetWidth;
        aboutButton.classList.add('aboutButtonBack');
    }, 5000);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonBack');
        void aboutButton.offsetWidth;
        for (let i = 0; i < navButtons.length; i++) {
            navButtons[i].classList.remove('navButtonGlow');
        }
    }, 6000);
};

return (
    <>  
        <title>Grimm's Corner - Projects</title>
        <h1>
            GrimmXIII Projects
        </h1>
        <main id='main'>
            <div className="">
                <h2>School Projects</h2>
                <div className="main-box-container">
                    <div className="box-left-projects">
                        <div className="box">
                            <h3>Grimm's Tech Repair Site</h3>
                            <p>
                                This was a school project tested on how much we learned from our 9 week HTML/CSS course, I decided to go with a tech design idea, mostly because I am very familiar with computers.<br/>
                                100/100 Points - A+
                            </p>
                        </div>
                    </div>
                </div>

                <h2>Personal Projects</h2>
                <div className="main-box-container">
                    <div className="box-left-projects">
                        <div className="box">
                            <img className='boxImg' src='/images/projects/engine/img3.png' />
                            <h3>Grimm Engine</h3>
                            <p>
                                Fork of Psych Engine, but with more stuff! Grimm Engine has features like 4 Player Multiplayer, Ranks, Profiles, and so much more!<br/>
                                Grimm Engine is still a W.I.P, and has not been released yet!
                            </p>
                            <Link href="/home/grimmEngine">
                                <button className="projButton">
                                    Go to Project Page
                                </button>
                            </Link>
                        </div>

                        <div className="box">
                            <img className='boxImg' src='/images/projects/hopes/img2.png' />
                            <h3>FNF Hopes and Dreams</h3>
                            <p>
                                Undertale in FNF!?!?!?!??!?! Yep, take the place as bf who fell in the underground!<br/>
                                The Demo is out! Sans song is leaked :O
                            </p>
                            <Link href="/home/fnfHopesAndDreams">
                                <button className="projButton">
                                    Go to Project Page
                                </button>
                            </Link>
                        </div>

                        <div className="box">
                            <h3>Grimm Bot</h3>
                            <p>
                                A witeral discowrd moderwater. Grimm Bot will be your discord server manager, it can handle bans, kicks, message deletes, and ect. mostly what you can expect from other bots. But unlike Mee6, no matter what happens, this bot will remain free forever!<br/>
                                GrimmBot is not released yet, however its still a W.I.P
                            </p>
                        </div>

                        <div className="box">
                            <h3>Grimm's Lair</h3>
                            <p>
                                My First website ever created with React.JS, Grimm's Lair, admittedly, does not have much to offer anymore. This site supports account creation, custom styles, and a html coder! (don't get your hopes up :)<br/>
                                Grimm's Lair is likely not planned to have an overhaul, but it might one day. Nevertheless, it is already released!
                            </p>
                        </div>
                    </div>

                    <div className="box-right-projects">
                        <div className="box">
                            <img className='boxImg' src='/images/projects/cracker/img5.png' />
                            <h3>Cracker Clicker</h3>
                            <p>
                                Better than Fortnite, Cracker Clicker is an non-idle clicker game! If you couldn't tell, you have to click the cracker to progress, when you get enough crackers, you get to meet lots of new friends! (This was created to circumvent my school banning Cookie Clicker)<br/>
                                While this game is completed, it is planned to have an overhaul. Be on the lookout for that
                            </p>
                            <Link href="/home/crackerClicker">
                                <button className="projButton">
                                    Go to Project Page
                                </button>
                            </Link>
                        </div>

                        <div className="box">
                            <h3>Grimm Music Player</h3>
                            <p>
                                Want a break from the ads? the Music Player will allow you to add your own .mp3 or .ogg files into a folder, and it can load those files into a list that you can play from it! There are also keyboard controls, along with animated or static backgrounds to make it look like whatever you would want!<br/>
                                Grimm Music Player has the core functionality already added, but there are still lots of key features missing to make it good.

                            </p>
                        </div>

                        <div className="box">
                            <h3>Grimm Screen</h3>
                            <p>
                                Grimm Screen plans to achive the goal of showing you relevent information, while at the same time looking cool B), It is planned that there will be modules, where you can configure displays from things like API's, news outlets, and hopefully more!<br/> 
                                While I have the idea, Grimm Screen has had no progress made yet.
                            </p>
                        </div>

                        <div className="box">
                            <h3>GrimmBlock</h3>
                            <p>
                                GrimmBlock is a tetris bot that has different multiple modes. the higher the mode, the better the playstyle, pps, and finesse that he has.<br/>
                                While I have the idea, GrimmBlock has had no progress made yet.
                            </p>
                        </div>
                    </div>
                </div>


                <h2>Dead Projects</h2>
                <div className="main-box-container">
                    <div className="box-left-projects">
                        <div className="box">
                            <h3>Super Undertale Bros</h3>
                            <p>
                                While I didn't make the original project, I did work on a reboot of the project. But at the time, I was running an Acer Laptop, so the system resources weren't the best, and my computer just didn't want to run with Unreal Engine. So due to this, I had to scrap this idea. I might bring it back, as I recently had just gotten a decent desktop. But no guarantees.
                            </p>
                        </div>
                    </div>
                </div>


                <p>
                    Bruh, I gotta work on these project titles
                </p>



            </div>
        </main>






    </>
    );
}
