import React, { useState, useEffect } from "react";
import { Link } from "wouter";

export default function Hopes() {


return (
    <>
        <title>Grimm's Corner - Yuh oh! a 404!</title>
        <main id="main">
            <div className="box top-box">
                <h1>Whoa there, buddy!</h1>
                <h6>404 Page not found, response of 0</h6>
                <p>This page don't exist, or at least not yet!</p>
            </div>
        </main>
    </>
    );
}
