import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {
    const [location, setLocation] = useLocation();
    let num = 0;

    function grimmFetch(loc) {
        //depricated. Kiss my ass grimmFetch :)
        var whatsthisweb = "home/";
        return "https://server.grimm.pw/files/" + whatsthisweb + loc;
    }

    useEffect(() => {
        const socialButtons = document.querySelectorAll('.social-email, .social-twitter, .social-spotify, .social-soundcloud, .social-youtube-solo, .social-youtube-trio, .social-discord, .social-gamebanana, .social-github, .social-gamejolt, .social-steam, .social-epic-games, .social-playstation, .social-minecraft, .social-tetrio');
  
        socialButtons.forEach(button => {
            const imgClass = button.classList[0].replace('social-', 'img-');
            const img = document.querySelector(`.${imgClass}`);
            const oldImg = document.querySelector(`.img-old`);

            if (img) {
                button.addEventListener('mouseenter', () => {
                    img.style.display = 'inline-block';
                    oldImg.style.display = 'none';
                });
    
                button.addEventListener('mouseleave', () => {
                    img.style.display = 'none';
                    oldImg.style.display = 'inline-block';
                });
            }
        });
    }, []);
  
    const warning = () => {
        const mainElem = document.getElementById("warning");
        const boom = new Audio('misc/boom.mp3');

        if (num < 5) {
            mainElem.classList.remove("shake");
            void mainElem.offsetWidth;
            mainElem.classList.add("shake");
            num = num + 1;
        } else if (num >= 5) {
            boom.play();
            mainElem.classList.add("shakeBadder");
            num = num + 1;
        }
    };
  
    function nopeShake(num) {
        const mainElem = document.getElementById(num === 1 ? "epicBad" : "minecraftBad");

        mainElem.classList.remove("shake");
        void mainElem.offsetWidth;
        mainElem.classList.add("shake");
    };
  
    const proj = (num, link) => {
        const button = document.getElementById("proj" + num);
        const warningDiv = document.getElementById("warning");
        button.classList.add('projLinkAnim');

        setTimeout(() => {
            if (num === '1' || num === '2' || num === '3') {
                setLocation("/" + link)

            } else {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                // warning();
            }
        }, 1000);
  
        setTimeout(() => {
            button.classList.remove('projLinkAnim');
            void button.offsetWidth;
            button.classList.add('projLinkAnimBack');
        }, 5000);

        setTimeout(() => {
            button.classList.remove('projLinkAnimBack');
            void button.offsetWidth;
        }, 6000);
    };
  
const about = () => {
const aboutButton = document.getElementById('aboutButton');
const navButtons = document.getElementsByClassName('navButton');
const navButton = document.getElementById('navButton2');
aboutButton.classList.add('aboutButtonMove');

setTimeout(() => {
    for (let i = 0; i < navButtons.length; i++) {
        navButtons[i].classList.add('navButtonGlow');
    }}, 500);

    setTimeout(() => {
        window.location.href = "/about"
    }, 1500);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonMove');
        void aboutButton.offsetWidth;
        aboutButton.classList.add('aboutButtonBack');
    }, 5000);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonBack');
        void aboutButton.offsetWidth;
        for (let i = 0; i < navButtons.length; i++) {
            navButtons[i].classList.remove('navButtonGlow');
        }
    }, 6000);
};

return (
    <>  
        <title>Grimm's Corner</title>
        <h1>
            GrimmXIII Edition
        </h1>
        <main id="main" className="">
            <div className="box top-box">
                <img className='mainPfp' src="/images/pfp.png"/>
                <h3 className="h3-right">Howdy! Im GrimmXIII</h3>
                <p>This is my little corner, everything GrimmXIII is here!</p>
                <p>I am a 18 Y/O Beginning Programmer. So far, I am mostly fluent in Web Design, however I want to begin learning Python, Javascript, and the C Family.</p>
                <div onClick={about} className="aboutButton" id="aboutButton">
                    <div className="aboutButtonBoxLeft"></div>
                    <p className="aboutButtonP" style={{marginTop:"0px", marginBottom:"0px"}}>My Story</p>
                    <div className="aboutButtonBoxRight"></div>
                </div>
            </div>

            <div className="main-box-container">
                <div className="box-left">
                    <div className="box">
                        <h4>GrimmXIII Projects</h4>
                        <h2 className="focus hidden" style={{marginTop: "20px", textAlign: "center", fontSize: "20px"}}>No Projects Focused Right Now</h2>
                        <div className="projWrapper">
                            <h2>Grimm Engine</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statB">Not Ready</b></h4>
                            <div className="projLink" onClick={() => proj('1', 'home/grimmEngine')} id="proj1">
                                Go to Project Page
                                <div className="projLink-box">
                                </div>
                            </div>
                            <p>A Fork of FNF's Psych Engine, Grimm Engine plans on bringing more function with LUA, along with adding some flair to it as well.</p>
                            <div className="lang">Flixel</div>
                            <div className="lang">LUA</div>
                        </div>

                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>Cracker Clicker</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statM">Playable, but not finished. <a href='https://cracker-clicker.glitch.me'>Link</a></b></h4>
                            <div className="projLink" onClick={() => proj('2', 'home/crackerClicker')} id="proj2">
                                Go to Project Page
                                <div className="projLink-box">
                                </div>
                            </div>
                            <p>A copy of Cookie Clicker (Mostly played at my school) However, nothing is earned automatically, it must be earned from a click! Get better CPC (Crackers Per Click) and dominate the Leaderboards! (Leaderboards not added yet)</p>
                            <div className="lang">React.js</div>
                        </div>

                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>FNF Hopes and Dreams</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statM">Demo Out! <a href=''>Link</a></b></h4>
                            <div className="projLink" onClick={() => proj('3', 'home/fnfHopesAndDreams')} id="proj3">
                                Go to Project Page
                                <div className="projLink-box">
                                </div>
                            </div>
                            <p>Ayo? BF Be rappin against the Undertale Crew?? now thats what im talkin about!</p>
                            <div className="lang">Flixel</div>
                            <div className="lang">LUA</div>
                        </div>

                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>Grimm Music Player</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statB">Not Ready (43% Progress)</b></h4>
                            <div style={{border: "1px solid red"}} className="projLink" onClick={() => proj('4', 'google.com')} id="proj4">
                                Page not Made
                                <div style={{border: "1px solid red"}} className="projLink-box">
                                </div>
                            </div>
                            <p>A Fork of FNF's Psych Engine, The Music player stripps the Friday Night Funkin aspect of everything, and just allows you to drop your own OGG Files in, and play then individually. This can be sweet to use if you are offline</p>
                            <p style={{color:"goldenrod"}}>Project Page will be added when enough progress is made</p>
                            <div className="lang">Flixel</div>
                        </div>

                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>Grimm Bot</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statB">Not Started</b></h4>
                            <div style={{border: "1px solid red"}} className="projLink" onClick={() => proj('5', 'google.com')} id="proj5">
                                Page not Made
                                <div style={{border: "1px solid red"}} className="projLink-box">
                                </div>
                            </div>
                            <p>A discord bot that aims at moderating a server, whilst at the same time, havin some cool ass features included with it as well :]</p>
                            <p style={{color:"goldenrod"}}>Project Page will be added when enough progress is made</p>
                            <div className="lang">Discord.js</div>
                        </div>
                        
                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>Grimm Screen</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statB">Not Ready</b></h4>
                            <div style={{border: "1px solid red"}} className="projLink" onClick={() => proj('6', '')} id="proj6">
                                Page not Made
                                <div style={{border: "1px solid red"}} className="projLink-box">
                                </div>
                            </div>
                            <p>Grimm Screen is ment as a full screen application that can keep track of certain things, such as events on your calander, alarms, reminders, and maybe things with APIs?</p>
                            <p style={{color:"goldenrod"}}>Project Page will be added when enough progress is made</p>
                            <div className="lang">Python</div>
                        </div>

                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>Grimm's Lair</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statG">Released! (No longer getting updates) <a href='https://grimmxiiiold.glitch.me'>Link</a></b></h4>
                            <div style={{border: "1px solid red"}} className="projLink" onClick={() => proj('7', 'google.com')} id="proj7">
                                No Project Page Yet
                                <div style={{border: "1px solid red"}} className="projLink-box">
                                </div>
                            </div>
                            <p>Mainly used for school, Grimm's Lair is just contains a couple of Unblocked games that should be unblocked.</p>
                            <div className="lang">React.js</div>
                        </div>

                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>GrimmBlock</h2>
                            <h2 className="focus hidden" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statG">Not Started</b></h4>
                            <div style={{border: "1px solid red"}} className="projLink" onClick={() => proj('8', 'google.com')} id="proj8">
                                No Project Page Yet
                                <div style={{border: "1px solid red"}} className="projLink-box">
                                </div>
                            </div>
                            <p>A Tetris bot thats main goal is to make you hate the game, keep the game to myself ya'know :]</p>
                            <div className="lang">Python</div>
                        </div>

                        <div className="divider"></div>

                        <div className="projWrapper">
                            <h2>This website</h2>
                            <h2 className="focus" style={{textAlign: "left", fontSize: "35px"}}>Focused Project</h2>
                            <h4>Status: <b className="statM">In Progress</b></h4>
                            <p>Of course im putting this here, This website is one of my biggest projects i've worked on here, and I think it deserves a spot on this list :]</p>
                            <div className="lang">HTML</div>
                        </div>

                        <h6>I need more creative Project Titles :[</h6>

                        </div>
                    </div>
                    <div className="box-right">
                    <div className="box">
                        <h4>GrimmXIII Appears!</h4>
                        <img className="img-old" src="/images/profiles/old.png" alt=""/>
                        <img className="img-email" src="/images/profiles/email.png" alt=""/>
                        <img className="img-twitter" src="/images/profiles/twitter.png" alt=""/>
                        <img className="img-spotify" src="/images/profiles/spotify.png" alt=""/>
                        <img className="img-soundcloud" src="/images/profiles/soundcloud.png" alt=""/>
                        <img className="img-youtube-solo" src="/images/profiles/youtube-solo.png" alt=""/>
                        <img className="img-youtube-trio" src="/images/profiles/youtube-trio.png" alt=""/>
                        <img className="img-discord" src="/images/profiles/discord.png" alt=""/>
                        <img className="img-gamebanana" src="/images/profiles/gamebanana.png" alt=""/>
                        <img className="img-github" src="/images/profiles/github.png" alt=""/>
                        <img className="img-gamejolt" src="/images/profiles/gamejolt.png" alt=""/>
                        <img className="img-steam" src="/images/profiles/steam.png" alt=""/>
                        <img className="img-epic-games" src="/images/profiles/epic-games.png" alt=""/>
                        <img className="img-playstation" src="/images/profiles/playstation.png" alt=""/>
                        <img className="img-minecraft" src="/images/profiles/minecraft.png" alt=""/>
                        <img className="img-tetrio" src="/images/profiles/tetrio.png" alt=""/>
                        <a href="mailto:grimmpublic13@gmail.com">
                            <button className="social-email">
                                <p className="a">Email</p>
                                <p className="b">grimmpublic13@gmail.com</p>
                            </button>
                        </a>
                    
                        <a href="https://google.com">
                            <button className="social-twitter">
                                <p className="a">Twitter</p>
                                <p className="b">@GrimmmXIII</p>
                            </button>
                        </a>
                    
                        <a href="https://google.com">
                            <button className="social-spotify">
                                <p className="a">Spotify</p>
                                <p className="b">GrimlockXIII</p>
                            </button>
                        </a>
                    
                        <a href="https://soundcloud.com/the-xiii-one">
                            <button className="social-soundcloud">
                                <p className="a">SoundCloud</p>
                                <p className="b">Grimm XIII</p>
                            </button>
                        </a>
                    
                        <a href="https://www.youtube.com/@GrimmXIIII">
                            <button className="social-youtube-solo">
                                <p className="a">YouTube (Solo)</p>
                                <p className="b">GrimmXIII</p>
                            </button>
                        </a>
                    
                        <a href="https://www.youtube.com/@TheRomanNumeralss">
                            <button className="social-youtube-trio">
                                <p className="a">YouTube (Trio)</p>
                                <p className="b">TheRomanNumerals</p>
                            </button>
                        </a>
                    
                        <a href="https://discord.com/users/769315356475916308">
                            <button className="social-discord">
                                <p className="a">Discord</p>
                                <p className="b">grimlockxiii</p>
                            </button>
                        </a>
                    
                        <a href="https://gamebanana.com/members/1853325">
                            <button className="social-gamebanana">
                                <p className="a">Gamebanana</p>
                                <p className="b">GrimmXIII</p>
                            </button>
                        </a>
                    
                        <a href="https://github.com/GrimXIII">
                            <button className="social-github">
                                <p className="a">Github</p>
                                <p className="b">GrimXIII</p>
                            </button>
                        </a>
                    
                        <a href="https://gamejolt.com/@GrimmXIII">
                            <button className="social-gamejolt">
                                <p className="a">Gamejolt</p>
                                <p className="b">GrimmXIII</p>
                            </button>
                        </a>
                    
                        <a href="https://steamcommunity.com/id/the-13th-one/">
                            <button className="social-steam">
                                <p className="a">Steam</p>
                                <p className="b">GrimmXIII</p>
                            </button>
                        </a>
                    
                        <a>
                            <button className="social-epic-games" onclick={() => nopeShake("epicBad")} id="epicBad">
                                <p className="a">Epic Games</p>
                                <p className="b">GrimlockXIII-YT</p>
                            </button>
                        </a>
                    
                        <a href="https://psnprofiles.com/GGrimmXIII">
                            <button className="social-playstation">
                                <p className="a">PlayStation</p>
                                <p className="b">GrimlockXIII-YT</p>
                            </button>
                        </a>
                    
                        <a>
                            <button className="social-minecraft" onclick={() => nopeShake("minecraftBad")} id="minecraftBad">
                                <p className="a">Minecraft</p>
                                <p className="b">MGFLAMINDIAMOND</p>
                            </button>
                        </a>
                    
                        <a href="https://ch.tetr.io/u/grimmxiii">
                            <button className="social-tetrio">
                                <p className="a">TETR.IO</p>
                                <p className="b">GRIMMXIII</p>
                            </button>
                        </a>
                    </div>
                    <div className='box'>
                        <h4>Grimm SMP <a style={{color:'yellow'}}>⬤</a></h4>
                        <h3>Forge 1.20.1</h3>
                        <h3>IP: Hidden for Now</h3>
                        <Link href='/grimmMc'>
                            <button className="projButton">
                                Go to Server Info Page
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    </>
    );
}
