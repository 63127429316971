import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {
    const [location, setLocation] = useLocation();
    let num = 0;

    useEffect(() => {
        const socialButtons = document.querySelectorAll('.social-email, .social-twitter, .social-spotify, .social-soundcloud, .social-youtube-solo, .social-youtube-trio, .social-discord, .social-gamebanana, .social-github, .social-gamejolt, .social-steam, .social-epic-games, .social-playstation, .social-minecraft, .social-tetrio');
  
        socialButtons.forEach(button => {
            const imgClass = button.classList[0].replace('social-', 'img-');
            const img = document.querySelector(`.${imgClass}`);
            const oldImg = document.querySelector(`.img-old`);

            if (img) {
                button.addEventListener('mouseenter', () => {
                    img.style.display = 'inline-block';
                    oldImg.style.display = 'none';
                });
    
                button.addEventListener('mouseleave', () => {
                    img.style.display = 'none';
                    oldImg.style.display = 'inline-block';
                });
            }
        });
    }, []);
  
    const warning = () => {
        const mainElem = document.getElementById("warning");
        const boom = new Audio('misc/boom.mp3');

        if (num < 5) {
            mainElem.classList.remove("shake");
            void mainElem.offsetWidth;
            mainElem.classList.add("shake");
            num = num + 1;
        } else if (num >= 5) {
            boom.play();
            mainElem.classList.add("shakeBadder");
            num = num + 1;
        }
    };
  
    function nopeShake(num) {
        const mainElem = document.getElementById(num === 1 ? "epicBad" : "minecraftBad");

        mainElem.classList.remove("shake");
        void mainElem.offsetWidth;
        mainElem.classList.add("shake");
    };
  
    const proj = (num, link) => {
        const button = document.getElementById("proj" + num);
        const warningDiv = document.getElementById("warning");
        button.classList.add('projLinkAnim');

        setTimeout(() => {
            if (num === '1' || num === '2' || num === '3') {
                setLocation("/" + link)

            } else {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                // warning();
            }
        }, 1000);
  
        setTimeout(() => {
            button.classList.remove('projLinkAnim');
            void button.offsetWidth;
            button.classList.add('projLinkAnimBack');
        }, 5000);

        setTimeout(() => {
            button.classList.remove('projLinkAnimBack');
            void button.offsetWidth;
        }, 6000);
    };
  
const about = () => {
const aboutButton = document.getElementById('aboutButton');
const navButtons = document.getElementsByClassName('navButton');
const navButton = document.getElementById('navButton2');
aboutButton.classList.add('aboutButtonMove');

setTimeout(() => {
    for (let i = 0; i < navButtons.length; i++) {
        navButtons[i].classList.add('navButtonGlow');
    }}, 500);

    setTimeout(() => {
        warning();
    }, 1500);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonMove');
        void aboutButton.offsetWidth;
        aboutButton.classList.add('aboutButtonBack');
    }, 5000);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonBack');
        void aboutButton.offsetWidth;
        for (let i = 0; i < navButtons.length; i++) {
            navButtons[i].classList.remove('navButtonGlow');
        }
    }, 6000);
};

return (
    <>  
        <title>Grimm's Corner</title>
        <h1>
            GrimmXIII
        </h1>
        <h4 style={{textAlign:'center', color:'white'}}>
            Who is this guy!?
        </h4>
        <main id='main'>

            <div className="box">
                <div className="projWrapper">
                    <img src="/images/timeline.png" className="projImgCenter"/>
                    <h3>Helloooo</h3>
                    <p>Howdy! Im GrimmXIII</p>
                    <p>I am a 18 Y/O Beginning Programmer. So far, I am mostly fluent in Web Design, however I want to begin learning Python, Javascript, and the C Family.</p>
                    <p>I have a condition where I want to start more projects than I can work on. this may be evident by how many un-started projects I have. However, this doesn't mean that they wont get done.</p>
                    <p>Stories are fun to write, same thing applies to Lore as well. Have I ever written one? No. Do I plan on it? Maybe... I suppose we shall see.</p>
                    <p>Sorry, but I don't have much for an About Me. I plan on adding more with time.</p>
                </div>
            </div>

            <div className="box">
                <div className="projWrapper">
                    <img src="/images/img1.png" className="projImgLeft"/>
                    <h3>Whats the deal with that name?</h3>
                    <p>I first off, had created my name from a skin I had on Minecraft. I had used an MC Skin creator which used presets, and you get to pick what you wanted. I thought that a lava shirt and diamond ore pants looked the coolest when I was 12</p>
                    <p>I thought long and hard on a name, and I came to the conclusion: The Blazing Diamond. Unique right? I found out the hard way 2 years later, that the name Blazing Diamond is quite the opposite. in fact, it seems like everybody had that name! Damnit!</p>
                    <p>So, what else could I use... I did get into this mobile game called Angry Birds Transformers. my favorite character was Grimlock. Keep this noted for a second.</p>
                    <p>In 7th Grade, I recall haring that people didn't understand what a Roman Numeral is. People would read them as the letter, not the number. This seemed nice to me because people knew my old channel name, and maybe, if I make it confusing enough for them, then I wouldn't hear about it at school anymore? (I dunno what my 13 Year old self was thinking)</p>
                    <p>Well, my lucky number was 13, this was because it was unlucky to everybody else. again, I dunno what I was thinking. But I thought that my lucky number in roman numerals would look cool. So, a + b = ab, and Grimlock + XIII = GrimlockXIII. Simple, and that was my new name.</p>
                    <p>Until I realized that Grimlock was copyright. I had found out about copyright law, sooooo... I need a new name again. Whatever should I do!? A few friends told me I should just get rid of "lock" from it, and just have GrimXIII, but that felt too simple, too normal. We still needed something unique.</p>
                    <p>So, I had thought of the genius idea... and hear me out... to add another "m" to Grimm. Now I know thats such a crazy idea, but it worked! GrimmXIII. a super unique name! (It also helped that I was a Kingdom Hearts fan, if you know, you know.)</p>
                    <p>This is pretty much where we are at the moment, I go by a few aliases if GrimmXIII is somehow taken, these are: GGrimmXIII, GrimmmXIII, or sometimes still GrimlockXIII. All official socials are in the Home Page.</p>
                </div>
            </div>

            <div className="box">
                <div className="projWrapper">
                    <img src="/images/img2.png" className="projImgRight"/>
                    <h3>You terminally online or somethin?</h3>
                    <p>No, not yet :)</p>
                    <p>Jokes aside, I have plenty of things outside of the *internet* most notable, is my small tech repair side hustle</p>
                    <p>Another hobby of mine are legos, now THOSE are fun AS HELL!! My main goal is to get a whole room just for those one day, that would be the dream! I spend a lot of my free time just building those things, and I don't regret it one bit!</p>
                    <p>Im also very interested in cars, both real, and hotwheels. They are just fun to see how they work, and a hotwheels collection is like the lego collection. It would be cool as hell!</p>
                    <p>I like IT Very much, I have been working in the field for a little bit now, but the Internet is very interesting to me.</p>
                    <p>I am being vague here, mostly because this is the internet. but this still gets the idear across.</p>

                </div>
            </div>

            <div className="box">
                <div className="projWrapper">
                    <p>There is not much more that I want to put onto here, because again, its the internet. but I appreciate that you read this page (even if you are just reading this part)</p>
                    <p>I do plan on adding more, cuz this does look a little bare</p>
                </div>
            </div>

        </main>







    </>
    );
}
