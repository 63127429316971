import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {
    const [location, setLocation] = useLocation();
    let num = 0;

    useEffect(() => {
        const socialButtons = document.querySelectorAll('.social-email, .social-twitter, .social-spotify, .social-soundcloud, .social-youtube-solo, .social-youtube-trio, .social-discord, .social-gamebanana, .social-github, .social-gamejolt, .social-steam, .social-epic-games, .social-playstation, .social-minecraft, .social-tetrio');
  
        socialButtons.forEach(button => {
            const imgClass = button.classList[0].replace('social-', 'img-');
            const img = document.querySelector(`.${imgClass}`);
            const oldImg = document.querySelector(`.img-old`);

            if (img) {
                button.addEventListener('mouseenter', () => {
                    img.style.display = 'inline-block';
                    oldImg.style.display = 'none';
                });
    
                button.addEventListener('mouseleave', () => {
                    img.style.display = 'none';
                    oldImg.style.display = 'inline-block';
                });
            }
        });
    }, []);
  
    const warning = () => {
        const mainElem = document.getElementById("warning");
        const boom = new Audio('misc/boom.mp3');

        if (num < 5) {
            mainElem.classList.remove("shake");
            void mainElem.offsetWidth;
            mainElem.classList.add("shake");
            num = num + 1;
        } else if (num >= 5) {
            boom.play();
            mainElem.classList.add("shakeBadder");
            num = num + 1;
        }
    };
  
    function nopeShake(num) {
        const mainElem = document.getElementById(num === 1 ? "epicBad" : "minecraftBad");

        mainElem.classList.remove("shake");
        void mainElem.offsetWidth;
        mainElem.classList.add("shake");
    };
  
    const proj = (num, link) => {
        const button = document.getElementById("proj" + num);
        const warningDiv = document.getElementById("warning");
        button.classList.add('projLinkAnim');

        setTimeout(() => {
            if (num === '1' || num === '2' || num === '3') {
                setLocation("/" + link)

            } else {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                // warning();
            }
        }, 1000);
  
        setTimeout(() => {
            button.classList.remove('projLinkAnim');
            void button.offsetWidth;
            button.classList.add('projLinkAnimBack');
        }, 5000);

        setTimeout(() => {
            button.classList.remove('projLinkAnimBack');
            void button.offsetWidth;
        }, 6000);
    };
  
const about = () => {
const aboutButton = document.getElementById('aboutButton');
const navButtons = document.getElementsByClassName('navButton');
const navButton = document.getElementById('navButton2');
aboutButton.classList.add('aboutButtonMove');

setTimeout(() => {
    for (let i = 0; i < navButtons.length; i++) {
        navButtons[i].classList.add('navButtonGlow');
    }}, 500);

    setTimeout(() => {
        warning();
    }, 1500);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonMove');
        void aboutButton.offsetWidth;
        aboutButton.classList.add('aboutButtonBack');
    }, 5000);

    setTimeout(() => {
        aboutButton.classList.remove('aboutButtonBack');
        void aboutButton.offsetWidth;
        for (let i = 0; i < navButtons.length; i++) {
            navButtons[i].classList.remove('navButtonGlow');
        }
    }, 6000);
};

return (
    <>  
        <title>Grimm's Corner</title>
        <div id='warning' className="warning">
            <img src="/images/warning.png" />
            <h2>Accounts are NOT available yet!</h2>
            <p>This is currently a Work in Progress, this page was already created so when they are released, they can be implimented easily.</p>
            <p>Its unknown as to when they are released, but just know that im workin on it!</p>
        </div>
        <h1>
            Your Grimm Account
        </h1>
        <main id='main'>
            <div className="main-box-container">
                <div className="box-left">
                    <div className="box">
                        <h2>Your Display</h2>
                        <h6>Display Name</h6>
                        <input value="GrimmXIII" readonly="readonly"></input>
                        <div className="inputBoxCon">
                            <div className="inputBox">
                                &nbsp;
                            </div>
                        </div>
                        <h6>User Tag</h6>
                        <input value="@grimmxiii" readonly="readonly"></input>
                        <div className="inputBoxCon">
                            <div className="inputBox">
                                &nbsp;
                            </div>
                        </div>


                        <h2>Friends</h2>
                        <h6>Allow any user to add you as a friend</h6>

                        <h2>Account</h2>
                        <h6>Change your email</h6>
                        <h6>Change your password</h6>
                        <h6>Logout</h6>



                        <div className="warningContainer"><div class="warningSign"></div></div>
                        <h6 style={{color:'red'}}>Delete your account!</h6>
                        <div className="warningContainer"><div class="warningSign"></div></div>



                        



                    </div>
                </div>
                <div className="box-right">
                    <div className="box">
                        <img src="/images/default.png" className="accountPFP"/>
                        <br/>
                        <button>Change Profile Photo</button>

                    </div>
                </div>
            </div>
        </main>






    </>
    );
}
