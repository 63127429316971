import * as React from "react";
import { Switch, Route, Router } from "wouter";
//Home
import Home from "../pages/home/home";
import crackerClicker from "../pages/home/cracker";
import hopes from "../pages/home/hopes"
import engine from "../pages/home/engine"
import MC from "../pages/home/mc";

//About
import About from "../pages/about/home";

//Status
import Status from "../pages/status/home";

//Stats
import Stats from "../pages/stats/home";

//Projects
import Projects from "../pages/projects/home";

//Blog
import Blog from "../pages/blog/home";

//Grimm Services
import grimmHome from "../pages/gs/home"

//Account
import Account from "../pages/account/home";


import error from "../pages/home/error"


export default () => (
    <Switch>
        <Route path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/home/fnfHopesAndDreams" component={hopes} />
        <Route path="/home/crackerClicker" component={crackerClicker} />
        <Route path="/home/grimmEngine" component={engine} />
        <Route path="/grimmMc" component={MC} />

        <Route path="/about" component={About} />

        <Route path="/status" component={Status} />

        <Route path="/stats" component={Stats} />

        <Route path="/projects" component={Projects} />

        <Route path="/blog" component={Blog} />

        <Route path="/grimmServices" component={grimmHome} />

        <Route path="/account" component={Account} />

        <Route component={error} />

    </Switch>
);
