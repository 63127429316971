import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {
    const [data, setData] = useState(null);
    const apiKey = process.env.REACT_APP_API;
    const serverUrl = process.env.REACT_APP_SERVER;
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    useEffect(() => {
    const fetchData = async () => {
        try {
        const response = await fetch('https://api.exaroton.com/v1/servers', {
            method: 'GET',
            mode: "cors",
            headers: {
            'Authorization': `${apiKey}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            },
        });
        const responseData = await response.json();
        setData(responseData);
        } catch (error) {
        console.error('Error fetching data from exaroton API:', error);
        }
    };

    fetchData();
    }, [apiKey]);



return (
    <>  
        <title>Grimm's Corner</title>
        <h1>
            GrimmXIII SMP
        </h1>
        <main id='main'>
            <div className="box-container">
                <div className="box">
                    <h2>
                        IP : [HIDDEN FOR NOW]
                    </h2>
                    <h4 style={{textAlign:"center"}}>
                        Version: Forge 1.20.1
                    </h4>
                </div>

                <div className="box colorWhiteHahahahahahaha">
                    <h2>Grimm MC</h2>
                    <p>Join the coolest server of them all!!! all with these simple steps!</p>

                    <h4>Step 1: Download the Mods ZIP File.</h4>
                    <p>
                        The server has its own mods installed, however, each client also needs these mods as well.<br/>
                        Click the link below to download the mods required on the server.<br/>
                        <a className="limk" href="https://drive.proton.me/urls/6890FXJW3W#2s25EKUUBSOX">Grimm Server Mods</a>
                    </p>

                    <h4>Step 2: Download and Install Forge 1.20.1 and the Minecraft Profile</h4>
                    <p>
                        Forge 1.20.1 is required to play on the server, but at least Forge makes it easy to install<br/>
                        <ul>
                            <li>1 - Click the link here: <a className="limk" href="https://files.minecraftforge.net/net/minecraftforge/forge/index_1.20.1.html">Forge 1.20.1</a></li>
                            <li>2 - Download the suggested version for forge (the one on the right)</li>
                            <li>3 - Ignore whatever happens on your screen, and wait 5 seconds. Once you do, you should see a download button in the top right corner</li>
                            <li>4 - Once downloaded, you need to run the file with Java (I hope you already have that) Right click the downloaded file, Click "Open With..." and select JAVA Binary</li>
                            <li>5 - Once the installer runs, leave all settings as default, and make sure that its installing 1.20.1</li>
                        </ul>
                    </p>
                    <h4>Step 3: Create Seperate Minecraft Profile for Server</h4>
                    <p>
                        <ul>
                            <li>1 - Once you boot up Minecaft again, you need to create a new installation. Click "Installations" on the top of the page</li>
                            <li>2 - Click "New Installation", and under "Version" Look for "Forge" (You can type it in). Make sure its Forge 1.20.1</li>
                            <li>3 - DO NOT MISS THIS! - For Installation Directory, you need to make a folder for Minecraft to install this game in. Its good to keep this all seperate from your normal installtion! Make a folder, and set the directory to that folder. The folder can be anywhere, even as a subfolder in the .minecraft folder, Just do not leave this blank!!!</li>
                            <li>4 - Its suggested that before you install. You go to "More Settings" And for Arguments, change the 2G to 4G. This will give the game more RAM, which is what it will likely need. 2G is still alright, but 4G and up is best for preformance.</li>
                            <li>5 - Once you are done, press install. and you should be all good! Run the new installation, and you are able to play on the server!</li>
                        </ul>
                    </p>

                    <p>
                        After all mods are installed, I would also suggest that if you have a lower end machine, that you install a mod like Forge Sodium, or something similar
                    </p>


                    <h4>Issues?</h4>
                    <p>
                        email me at <a className="limk" href="support@grimmxiii.com">support@grimmxiii.com</a>
                    </p>
                </div>
            </div>
       
            <div className="box">
                Bro, API is a pain in the ass to setup

                <div className='creditsBox'>
                    <img src="images/credits/aether.png" />
                    <h3>The Aether</h3>
                    <p>Made By: TheAetherTeam</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/aether')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/ampnether.png" />
                    <h3>Amplified Nether</h3>
                    <p>Made By: Starmute</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/amplified-nether')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/apple.png" />
                    <h3>Appleskin</h3>
                    <p>Made By: squeek502</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/appleskin')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/AOTsM.png" />
                    <h3>Armor, ores, tools, so more</h3>
                    <p>Made By: ctoilcha_</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/armor-ores-tools-so-more')}>
                        Go to Link
                    </a>
                </div>
                
                <div className='creditsBox'>
                    <img src="images/credits/betteradvancements.png" />
                    <h3>Better Advancements</h3>
                    <p>Made By: way2muchnoise</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/better-advancements')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/chimes.png" />
                    <h3>Chimes</h3>
                    <p>Made By: Blewberry Pie</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/chimes')}>
                        Go to Link
                    </a>
                </div>
                
                <div className='creditsBox'>
                    <img src="images/credits/clumps.gif" />
                    <h3>Clumps</h3>
                    <p>Made By: Jaredlll08</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/clumps')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/connectedglass.png" />
                    <h3>Connected Glass</h3>
                    <p>Made By: SuperMarijn642</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/connected-glass')}>
                        Go to Link
                    </a>
                </div>
                
                <div className='creditsBox'>
                    <img src="images/credits/controlling.png" />
                    <h3>Controlling</h3>
                    <p>Made By: Jaredlll08</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/controlling')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/saddle.png" />
                    <h3>Craftable Saddles</h3>
                    <p>Made By: alexdaum1</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/craftable-saddles')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/ghast.png" />
                    <h3>Crying Ghasts</h3>
                    <p>Made By: Serilum</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/craftable-saddles')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/ores.png" />
                    <h3>The Age of Ores (Crystalcraft Unlimited)</h3>
                    <p>Made By: Lcs128x</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/the-age-of-ores-crystalcraft-unlimited-java')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/dragon.png" />
                    <h3>Dragon Mounts: Legacy</h3>
                    <p>Made By: Kay9Unit</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/dragon-mounts-legacy')}>
                        Go to Link
                    </a>
                </div>

                
                <div className='creditsBox'>
                    <img src="images/credits/leaf.png" />
                    <h3>Fast Leaf Decay</h3>
                    <p>Made By: olafskiii</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/fast-leaf-decay')}>
                        Go to Link
                    </a>
                </div>
                
                <div className='creditsBox'>
                    <img src="images/credits/fast.png" />
                    <h3>FastSuite</h3>
                    <p>Made By: Shadows_of_Fire</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/fastsuite')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/illager.png" />
                    <h3>Illager Trader</h3>
                    <p>Made By: Leonxkingx</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/illager-trader')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/furnace.png" />
                    <h3>Iron Furnaces [FORGE]</h3>
                    <p>Made By: XenoMustache</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/iron-furnaces')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/border.png" />
                    <h3>Item Borders [Forge]</h3>
                    <p>Made By: Grend_G</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/item-borders')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/jei.jpeg" />
                    <h3>Just Enough Items (JEI)</h3>
                    <p>Made By: mezz</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/jei')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/mobheads.png" />
                    <h3>Just Mob Heads</h3>
                    <p>Made By: Serilum</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/just-mob-heads')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/turret.png" />
                    <h3>K-Turrets</h3>
                    <p>Made By: Krosenut</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/k-turrets')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/villagerTrades.png" />
                    <h3>More Villager Trades</h3>
                    <p>Made By: TheRealCursed_Warrior</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/more-villager-trades')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/underground.png" />
                    <h3>more underground structures</h3>
                    <p>Made By: tombkingsts</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/more-underground-structures')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/bows.png" />
                    <h3>More Bows and Arrows</h3>
                    <p>Made By: Lupin</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/more-bows-and-arrows')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/plates.png" />
                    <h3>More Beautiful Pressure Plates</h3>
                    <p>Made By: 00theblackwolf00</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/more-beautiful-pressure-plates')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/moreVillagers.png" />
                    <h3>More Villagers</h3>
                    <p>Made By: SameDifferent</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/more-villagers')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/reds.png" />
                    <h3>Red's More Structures</h3>
                    <p>Made By: redstone3game</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/reds-more-structures')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/terra.png" />
                    <h3>Terralith</h3>
                    <p>Made By: Starmute</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/terralith')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/bee.png" />
                    <h3>The Bumblezone</h3>
                    <p>Made By: telepathicgrunt</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/the-bumblezone-forge')}>
                        Go to Link
                    </a>
                </div>
                
                <div className='creditsBox'>
                    <img src="images/credits/tras.png" />
                    <h3>Trash Cans</h3>
                    <p>Made By: SuperMartijn642</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/trash-cans')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/twilight.png" />
                    <h3>The Twilight Forest</h3>
                    <p>Made By: Benimatic</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/the-twilight-forest')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/waystones.png" />
                    <h3>Waystones</h3>
                    <p>Made By: BlayTheNinth</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/waystones')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/waystonesPet.png" />
                    <h3>Waystones Teleport Pets</h3>
                    <p>Made By: precisemodpacker</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/waystones-teleport-pets')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/2023.png" />
                    <h3>What Are You Voting For? 2023</h3>
                    <p>Made By: alexandersfunandgames</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/what-are-you-voting-for-2023')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/minimap.png" />
                    <h3>Xaero's Minimap</h3>
                    <p>Made By: xaero96</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/xaeros-minimap')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/mapwaystone.png" />
                    <h3>Xaero's Minimap & World Map - Waystones Compatibility</h3>
                    <p>Made By: precisemodpacker</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/xaeros-minimap-world-map-waystones-compability')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/minimap.png" />
                    <h3>Xaero's World Map</h3>
                    <p>Made By: xaero96</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/xaeros-world-map')}>
                        Go to Link
                    </a>
                </div>

                -----------

                <div className='creditsBox'>
                    <img src="images/credits/wold.png" />
                    <h3>Blackwolf Library</h3>
                    <p>Made By: 00theblackwolf00</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/blackwolf-library')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/collective.png" />
                    <h3>Collective</h3>
                    <p>Made By: Serilum</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/collective')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/iceberg.png" />
                    <h3>Iceberg</h3>
                    <p>Made By: Grend_G</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/iceberg')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/prism.png" />
                    <h3>Prism</h3>
                    <p>Made By: Grend_G</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/prism-lib')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/satako.png" />
                    <h3>Satako Library</h3>
                    <p>Made By: Krosenut</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/satako-library')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/search.png" />
                    <h3>Searchables</h3>
                    <p>Made By: Jaredlll08</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/searchables')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/superconfig.png" />
                    <h3>SuperMartijn642's Config Lib</h3>
                    <p>Made By: SuperMartijn642</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/supermartijn642s-config-lib')}>
                        Go to Link
                    </a>
                </div>

                <div className='creditsBox'>
                    <img src="images/credits/supercore.png" />
                    <h3>SuperMartijn642's Core Lib</h3>
                    <p>Made By: SuperMartijn642</p>
                    <a onClick={() => openInNewTab('https://www.curseforge.com/minecraft/mc-mods/supermartijn642s-core-lib')}>
                        Go to Link
                    </a>
                </div>

                Is there a credit thats incorrect? or you want your mod removed? Email me at email me at <a className="limk" href="support@grimmxiii.com">support@grimmxiii.com</a>



            </div>


        </main>






    </>
    );
}
