import React, {useState, useEffect} from 'react';
import { Link } from 'wouter';

export default function Home() {

//onClick={() => proj('1', '')}
return (
    <>
        <title>Grimm's Corner - Grimm Engine</title>
        <div id='warning' className="warning">
            <img src="/images/warning.png"/>
            <h2>This Project has not been released yet!</h2>
            <p>This is still a WIP, and not finished yet, none of the links lead anywhere yet, until I get this project out.</p>
        </div>
        <h1>
            Grimm Engine
        </h1>
        <main id="main">
            <div className="box top-box">
                <p>Grimm Engine is a fork of Psych Engine, ment to bring more things that an arrow game has to offer, such as Ranks, Modifiers, Multiplayer, and more!</p>
            </div>
            <div className="main-box-container">
                <div className="box-left">
                    <div className="box">
                        <h2 className="creditsTitle">Credits</h2>
                        <div className="creditsWrapper">
                            <div className="credit">
                                <img src="/images/projects/engine/credits/grimm.png" />
                                <p>Creator</p>
                                <p>GrimmXIII</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="box-right">
                    <div className="box">
                        <div className="projLink" id="proj1">
                            Gamebanana Page
                            <div className="projLink-box">
                            </div>
                        </div>
                        <div className="projLink" id="proj2">
                            Github Page
                            <div className="projLink-box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1>
                What this has to offer...
            </h1>
            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/engine/img7.png" className="projImgLeft"/>
                    <h3>Start Menu</h3>
                    <p>Have access to settings and gameplay boosters and options before the song starts! Needs to change your controls in a flash? Well now you can! or maybe you cant to play Multiplayer? thats here too! This menu can also be disabled as well if it buggs ya too much!</p>
                </div>
            </div>

            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/engine/img8.png" className="projImgRight"/>
                    <h3>End Screen</h3>
                    <p>Sometimes its hard to tell how well you did, but not anymore! the end screen shows you info like notes hit/missed, accuracy, to even your Rank! Rub it in your friends face that you get a B and they both got Fs</p>
                </div>
            </div>


            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/engine/img3.png" className="projImgLeft"/>
                    <h3>Four Player Support!</h3>
                    <p>Grimm Engine uses a multiplayer script made by <a href="https://gamebanana.com/mods/395002">Super_Hugo</a> to turn the game into 4 player fun! the song plays twice, P1 and P2 go first, then P3 and P4 go! the engine also gives a UI for setting Controls! The Engine also supports usernames! so you wont be called "Player 1" or "Player 3". (but thats what it defaults to)</p>
                </div>
            </div>


            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/engine/img6.png" className="projImgRight"/>
                    <h3>More Animations!?</h3>
                    <p>Small animation tweaks have been added (that are all toggleable) to make sure that there are always moving things on the screen. Things such as Icon Bop, or Health Warning.</p>
                </div>
            </div>

            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/engine/img4.png" className="projImgLeft"/>
                    <h3>Personalized Icons</h3>
                    <p>Wanna add a little bit of yourself to the game? well now you can! you can make an icon-grid for your username which will put your icon next to the character your playing as. :]</p>
                </div>
            </div>
            
            <div className="box">
                <div className="projWrapper">
                    <img src="/images/projects/engine/img5.png" className="projImgRight"/>
                    <h3>Come give it a shot! (once its out)</h3>
                    <p>This mod may take some time to release, but when it does, it'll be free, so why not give it a try eh?</p>
                </div>
            </div>



            <div className="main-box-container">
                <div className="box-left">

                    <div className="box">
                        <h2 style={{fontSize: "45px;"}}>Updates!</h2>
                        <div className="update">
                            <h2>grimm.pw Creation Status</h2>
                            <h6>v1.0.0 - Jan 17 2024</h6>
                            <ul>
                                <li>Engine is Unstable, but still runnable</li>
                                <li style={{color: "red;"}}>Project still unreleased</li>
                                <li>Added a Start Menu</li>
                                <li>4 Player Support Added</li>
                                <li>4 Player Controls Added</li>
                                <li>Created LUA Animations in Options</li>
                                <li>Added an Ranking System</li>
                                <li>Ranking system now includes missed notes to influence rank</li>
                                <li>Added an Rage Bar System</li>
                                <li>Rage Bar now multiplies score for hit notes</li>
                                <li>Added Exit button before you begin the song</li>
                                <li>All 4 Players can have customizable usernames</li>
                                <li>All 4 Players can add their own icon grid to appear alongside their playing character</li>
                                <li>Modified Freeplay menu to look cooler :]</li>
                                <li>Modified Score Text to display more info</li>
                                <li>Added button to modify game settings before song start</li>
                                <li>Added button to change Gameplay Modifiers from start screen</li>
                                <li>Added invincibility option, nothing can kill you!</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="box-right">
                    <div className="box">
                        <div className="projLink" id="proj3">
                            Gamebanana Page
                            <div className="projLink-box">
                            </div>
                        </div>
                        <div className="projLink" id="proj4">
                            Github Page
                            <div className="projLink-box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    </>
    )
}