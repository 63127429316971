import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
// import {exchangeCodeForAccessToken, exchangeNpssoForCode, makeUniversalSearch, getUserTrophiesEarnedForTitle } from "psn-api";
export default function Home() {

    return (
    <>  
        <title>Grimm's Corner</title>
        <div id='warning' className="warning">
            <img src="/images/warning.png" />
            <h2>This website is legit being developed on!</h2>
            <p>This website is a work in progress, I didnt share the link, so good job on getting here! but dont expect much, the reason is up is so I can test er out. But hey, nice to see ya, stop by every now and again to see sweet ass updates! :)</p>
            <p>Just realized that I left the link on Discord. but hey, still glad your're here!</p>
        </div>
        <h1>
            GrimmXIII
        </h1>
        <main id='main'>
            <div className="box">
                <h3>Trophy Counts</h3>
            
                <div className="trophyHead">
                    <h1>
                        Playstation
                    </h1>
                    GGrimmXIII
                </div>
                <div className="trophyContainer">
                    <br/>
                    <div className="trophyProfile">
                        <img src="/images/profiles/playstation.png" />
                    </div>

                    <div className="trophyBox">
                        Bronze:<br/>
                        864
                    </div>

                    <div className="trophyBox">
                        Silver:<br/>
                        273
                    </div>

                    <div className="trophyBox">
                        Gold:<br/>
                        126
                    </div>

                    <div className="trophyBox">
                        Platinum:<br/>
                        18
                    </div>

                </div>


                <div className="trophyHead">
                    <h1>
                        Steam
                    </h1>
                    GrimmXIII / GrimlockXIII
                </div>
                <div className="trophyContainer">
                    <br/>
                    <div className="trophyProfile">
                        <img src="/images/profiles/steam.png" />
                    </div>

                    <div className="trophyBox">
                        Achivements:<br/>
                        160
                    </div>

                </div>

                <div className="trophyHead">
                    <h1>
                        GameJolt
                    </h1>
                    GrimmXIII / GrimlockXIII
                </div>
                <div className="trophyContainer">
                    <br/>
                    <div className="trophyProfile">
                        <img src="/images/profiles/gamejolt.png" />
                    </div>

                    <div className="trophyBox">
                        Achivements:<br/>
                        26
                    </div>

                    <div className="trophyBox">
                        GameJolt Achivements:<br/>
                        2
                    </div>

                </div>


                <div className="trophyHead">
                    <h1>
                        Gamebanana
                    </h1>
                    GrimmXIII
                </div>
                <div className="trophyContainer">
                    <br/>
                    <div className="trophyProfile">
                        <img src="/images/profiles/gamebanana.png" />
                    </div>

                    <div className="trophyBox">
                        Medals:<br/>
                        8
                    </div>

                    <div className="trophyBox">
                        Points:<br/>
                        627
                    </div>

                </div>


                <div className="trophyHead">
                    <h1>
                        TETR.IO
                    </h1>
                    GrimmXIII
                </div>
                <div className="trophyContainer">
                    <br/>
                    <div className="trophyProfile">
                        <img src="/images/profiles/tetrio.png" />
                    </div>

                    <div className="trophyBox">
                        <img src='https://tetr.io/res/league-ranks/s+.png' />
                        TR:<br/>
                        20467.18
                    </div>

                    <div className="trophyBox">
                        Level:<br/>
                        455
                    </div>

                    <div className="trophyBox">
                        XP:<br/>
                        1,633,032
                    </div>

                    <div className="trophyBox">
                        Badges:<br/>
                        3
                    </div>

                </div>

                <div className="trophyContainer">
                    <div className="trophyBox" style={{textAlign:'center', marginLeft:'20px', marginRight:'20px'}}>
                        <h4>
                            In no way do I feel like coding a proxy that can send requests every minute for updated numbers just yet. I will one day, but it aint that day yet. till then, all numbers here will be updated manually. Most likely Bi-Weekly
                        </h4>
                        <h4>
                            Last Update: February 22nd, 2024 (2/22/24) 
                        </h4>

                    </div>
                </div>

                {/*
                <div className="trophyContainer">
                    <div className="trophyHead">
                        <h1>
                            Clash of Clans
                        </h1>
                    </div>
                    <br/>

                    <div className="trophyBox">
                        Town Hall:<br/>
                        11, 8, 3, 2
                    </div>

                    <div className="trophyBox">
                        Trophies:<br/>
                        
                    </div>

                </div>
                */}


            </div>
        </main>






    </>
    );
}
